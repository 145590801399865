import { applyVueInReact, connectVuex } from "vuereact-combined";
import React, { Component } from "react";
import axios from "@/axios";
import LoadingSpinner from "@/components/LoadingSpinner";
import Payment from "../../components/payment/Payment";

//TODO PAYMENT
class InternetBillBroadlink extends Component {
  LoadingSpinnerComp = applyVueInReact(LoadingSpinner);

  constructor() {
    super();
    this.state = {
      loading: true,
      username: "",
    };
  }
  componentDidMount() {
    this.setState({ loading: false });
  }
  handleUserNameChange = (event) => {
    this.setState({ username: event.target.value });
  };
  checkBroadlinkAccount = async (event) => {
    event.preventDefault();

    if (!this.state.username) {
      return this.setState({
        message: "Please enter your Firstlink username.",
      });
    }
    this.setState({ loading: true });
    const data = await axios.post(
      "/api/v2/services/broadLink/checkBroadLinkAccount",
      {
        CustomerId: this.state.username,
      }
    );
    this.setState({ loading: false, message: "" });
    if (data.data && data.data.response) {
      if (data.data.response.Code !== "000") {
        this.setState({ message: data.data.response.Message });
      } else {
        this.setState({ reports: data.data.response });
      }
    }
  };
  render() {
    return (
      <div className="container">
        <div className="wallet-form-box card">
          <div className="card-body">
            <h5 className="card-title"> Braodlink Payment</h5>
            <a href="/" className="btn btn-close">
              <i className="fa fa-times"></i>
            </a>
            {this.state.message && (
              <div className="form-group fade-animation">
                <div className="show-message">{this.state.message}</div>
              </div>
            )}
            <div className="row">
              <div className="col-md-7">
                {this.state.reports ? (
                  <>
                    <></>
                  </>
                ) : (
                  <form>
                    <div className="form-group">
                      <label>
                        Username <i className="fa fa-asterisk"></i>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Type Username and press enter."
                        value={this.state.username}
                        name="username"
                        onChange={this.handleUserNameChange}
                      />
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={this.checkBroadlinkAccount}
                    >
                      Search
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default InternetBillBroadlink;
